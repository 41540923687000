/* globals document */
import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import * as dotenv from 'dotenv';

// import { BrowserRouter as Router } from 'react-router-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// import createBrowserHistory from 'history/createBrowserHistory';
import startLogger, { setSentryUser } from './logger';
import StoreManager from './store';
import routes from './routes';
import { unregister } from './registerServiceWorker';

// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../node_modules/font-awesome/css/font-awesome.min.css';
import './stylesheets/app.css';
import Utils from './services/utils';

// initialize dotenv
dotenv.config();

// starting logger service
startLogger();

// set the title
document.title = `${Utils.getFoundationType('S')} - ${document.title}`;

const subscriptions = {};

const history = createBrowserHistory();
const storeManager = new StoreManager();
const store = storeManager.configureStore(history);

subscriptions.unsubscribeSentryStatus = setSentryUser();
render(
  <Provider store={store}>
    <Router history={history}>{routes}</Router>
  </Provider>,
  document.getElementById('crypto-app')
);

// Disable the service worker
unregister();
